import { FC, useEffect, useMemo, useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useForm, Controller, useFormState } from 'react-hook-form'
import {
   selectOrder,
   getOrderById,
   useAppDispatch,
   useAppSelector,
   selectFetch,
   resetOrderDetail,
   updateOrder,
} from '../../../redux'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import {
   BackButton,
   Input,
   CircleLoading,
   CommonInfo,
   Table,
   TableRowType,
   Select,
   SelectOption,
   Button,
   FilterType,
   TableCellDataType,
} from '../../../components'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg'
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import RedeemIcon from '@mui/icons-material/Redeem'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'
import { ReactComponent as VoucherIcon } from '../../../assets/icons/voucher.svg'
import { getAddress, getDate, getOrderStatus, getPaymentMethod } from '../../../utils'
import {
   AddressDetail,
   OrderStatusNumber,
   OrderStatusString,
} from '../../../types'
import { colorPalette, typography } from '../../../config'
import { orderUpdateSchema } from '../../../schemas/orderSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { OrderData, Status } from '../../../types'
import { useTranslation } from 'react-i18next'
import { getNumberWithComma } from '../../../utils/get-price'

interface OrderUpdateFormValues {
   status?: OrderStatusNumber
   note: string
}

const OrderDetail: FC = () => {
   const { orderId } = useParams()
   const dispatch = useAppDispatch()
   const { orderLoading, orderDetail } = useAppSelector(selectOrder)
   const { response } = useAppSelector(selectFetch)
   const [updatedDetail, setUpdatedDetail] = useState<boolean>()

   const { t } = useTranslation(['common', 'components', 'rows', 'inputs'])

   const createdDate = getDate(Number(orderDetail?.created_at))
   const updatedDate = getDate(Number(orderDetail?.updated_at))
   const defaultValues = {
      status: orderDetail?.status,
      note: orderDetail?.note,
      address_detail: orderDetail?.address_detail,
      updated_at: orderDetail?.updated_at,
      user_id: orderDetail?.user_id,
      order_items: orderDetail?.order_items,
      phone_number: orderDetail?.phone_number,
      grand_total: orderDetail?.grand_total,
      created_at: orderDetail?.created_at,
      customer_name: orderDetail?.customer_name,
      voucher_code: orderDetail?.voucher_code,
   }

   const {
      control,
      handleSubmit,
      reset,
      setValue,
      watch,
      formState: { errors, isDirty },
   } = useForm<OrderUpdateFormValues>({
      defaultValues,
      resolver: yupResolver(orderUpdateSchema),
   })
   const { dirtyFields } = useFormState({ control })

   const headCells = useMemo(
      () => [
         {
            isId: true,
            id: '_id',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: '',
         },
         {
            id: 'item_name',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('order.items.name', { ns: 'rows' }),
         },
         {
            id: 'quantity',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.RANGE,
            label: t('order.items.quantity', { ns: 'rows' }),
         },
         {
            id: 'final_price',
            numeric: true,
            disablePadding: false,
            filterType: FilterType.RANGE,
            label: t('order.items.price', { ns: 'rows' }),
         },
         {
            id: 'promotion_program_id',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('order.items.program', { ns: 'rows' }),
         },
      ],
      []
   )

   const onSubmit = (data: OrderUpdateFormValues) => {
      const status = getOrderStatus(parseInt(data.status as unknown as string))

      if (status !== 'Invalid status') {
         dispatch(
            updateOrder({
               orderId: orderId as string,
               payload: { ...data, status },
            })
         )
      }
   }

   const orderStatusOptions = useMemo(() => {
      const parsedIntOptions = Object.values(OrderStatusNumber).filter(
         (value) => Number(value) >= 0
      ) as unknown as OrderStatusNumber[]
      if (orderDetail?.status === 2 || orderDetail?.status === 3)
         return parsedIntOptions.filter((op) => op === orderDetail?.status)

      if (orderDetail?.status) {
         return parsedIntOptions.filter((num) => num >= orderDetail?.status)
      }

      return parsedIntOptions
   }, [orderDetail])

   const rows: TableRowType[] = useMemo(() => {
      if (orderDetail) {
         return orderDetail?.order_items.map((item) => ({
            key: item.product_id,
            cells: [
               {
                  identifier: 'product_id',
                  type: TableCellDataType.ID,
                  data: item.product_id,
               },
               {
                  identifier: 'item_name',
                  type: TableCellDataType.TEXT,
                  data: item.item_name,
               },
               {
                  identifier: 'quantity',
                  type: TableCellDataType.UNIT,
                  data: item.quantity,
               },
               {
                  identifier: 'final_price',
                  type: TableCellDataType.UNIT,
                  data: item.final_price,
                  unit: 'đ',
               },
               {
                  identifier: 'promotion_program_id',
                  type: TableCellDataType.TEXT,
                  data: item.promotion_program_id ?? 'x/-',
               },
            ],
         }))
      }

      return []
   }, [orderDetail])

   useEffect(() => {
      if (orderId) {
         setUpdatedDetail(false)
         dispatch(getOrderById(orderId))
      }

      return () => {
         dispatch(resetOrderDetail())
      }
   }, [orderId])

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   useEffect(() => {
      if (!!orderDetail && !orderLoading && !updatedDetail) {
         Object.keys(defaultValues).forEach((key) => {
            setValue(
               key as keyof OrderUpdateFormValues,
               orderDetail[key as keyof OrderData] as unknown as any
            )
         })
         setUpdatedDetail(true)
      }
      watch()
   }, [orderDetail, orderId, updatedDetail])

   const renderForm = useMemo(
      () => (
         <>
            <PageTitle title={t('blockTitle.orderStatus')} type='content' />
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Controller
                     name='status'
                     control={control}
                     rules={{ required: true }}
                     render={({ field: { value, ...restField } }) => {
                        return (
                           <Select
                              label={
                                 t('order.status.label', {
                                    ns: 'inputs',
                                 }) as string
                              }
                              placeholder={
                                 t('order.status.placeholder', {
                                    ns: 'inputs',
                                 }) as string
                              }
                              defaultValue={orderDetail?.status}
                              value={watch('status')}
                              type='text'
                              error={!!errors.status}
                              helperText={errors.status?.message}
                              {...restField}
                           >
                              {orderStatusOptions.map((option) => {
                                 return (
                                    <SelectOption key={option} value={option}>
                                       {t(`status.${getOrderStatus(option)}`)}
                                    </SelectOption>
                                 )
                              })}
                           </Select>
                        )
                     }}
                  />
               </Grid>
               <Grid item xs={12}>
                  <Controller
                     name='note'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           disabled={!dirtyFields.status}
                           label={
                              t('order.note.label', { ns: 'inputs' }) as string
                           }
                           fullWidth
                           multiline
                           placeholder={
                              t('order.note.label', { ns: 'inputs' }) as string
                           }
                           type='text'
                           error={!!errors.note}
                           helperText={errors.note?.message}
                           rows={4}
                           minRows={4}
                           {...field}
                        />
                     )}
                  />
               </Grid>
            </Grid>
         </>
      ),
      [
         t,
         orderDetail,
         control,
         watch,
         errors.status,
         errors.note,
         orderStatusOptions,
         dirtyFields.status,
         orderLoading,
      ]
   )

   const renderInfo = useMemo(
      () => (
         <>
            <PageTitle title={t('blockTitle.general')} type='content' />
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <CommonInfo
                     icon={UserIcon}
                     label={'Tên người dùng'}
                     value={`${orderDetail?.customer_name || 'Guest'}`}
                  />
               </Grid>
               <Grid item xs={12}>
                  <CommonInfo
                     muiIcon={<AttachMoneyIcon color='primary' />}
                     label={t('order.total', { ns: 'rows' })}
                     value={`${getNumberWithComma(
                        orderDetail?.grand_total || 0
                     )} đ`}
                  />
               </Grid>
               {orderDetail?.payment_method ? <Grid item xs={12}>
                  <CommonInfo
                     muiIcon={<AttachMoneyIcon color='primary' />}
                     label="Phương thức thanh toán"
                     value={getPaymentMethod(orderDetail?.payment_method)}
                  />
               </Grid> : null}
               {orderDetail?.voucher_code && (
                  <Grid item xs={12}>
                     <CommonInfo
                        muiIcon={<RedeemIcon color='primary' />}
                        label={t('order.code', { ns: 'rows' })}
                        value={`${orderDetail?.voucher_code}`}
                     />
                  </Grid>
               )}
               <Grid item xs={12}>
                  <CommonInfo
                     icon={PhoneIcon}
                     label={t('order.phone', { ns: 'rows' })}
                     value={`${orderDetail?.phone_number}`}
                  />
               </Grid>
               <Grid item xs={12}>
                  <CommonInfo
                     icon={LocationIcon}
                     label={t('order.address', { ns: 'rows' })}
                     value={getAddress(
                        orderDetail?.address_detail as AddressDetail
                     )}
                  />
               </Grid>
               <Grid item xs={12}>
                  <CommonInfo
                     icon={ClockIcon}
                     label={t('common.createdAt', { ns: 'rows' })}
                     value={`${createdDate.time} - ${createdDate.day}`}
                  />
               </Grid>
            </Grid>
         </>
      ),
      [
         createdDate.day,
         createdDate.time,
         orderDetail?.address_detail,
         orderDetail?.customer_name,
         orderDetail?.grand_total,
         orderDetail?.phone_number,
         orderDetail?.voucher_code,
         t,
      ]
   )

   const renderOrderItems = useMemo(
      () => (
         <Table
            title={t('tableTitle.items')}
            headCells={headCells}
            rows={rows}
            initialSort='item_name'
            initialSearchTermType='product_id'
         />
      ),
      [headCells, rows, t]
   )

   return (
      <PageWrapper>
         <PageHeader>
            <Stack
               direction='row'
               alignItems='center'
               justifyContent='space-between'
               flexGrow={1}
            >
               <Stack direction='row' alignItems='center'>
                  <BackButton to='/orders' />
                  <PageTitle
                     title={`${t('pageTitle.order.detail')} ${orderId}`}
                     type='header'
                  />
               </Stack>
               <Stack direction='row' alignItems='center'>
                  {!orderLoading && (
                     <Typography
                        className={typography.pc.helpSemi}
                        color={colorPalette.lightGrey}
                        textTransform='none'
                        sx={{ marginRight: '8px' }}
                        fontStyle='italic'
                     >
                        <strong>{t('common.updatedAt', { ns: 'rows' })}</strong>{' '}
                        {updatedDate.time} - {updatedDate.day}
                     </Typography>
                  )}
                  <Button
                     disabled={!isDirty}
                     customsize='sm'
                     variant='contained'
                     type='submit'
                     onClick={handleSubmit(onSubmit)}
                  >
                     {t('buttons.common.save', { ns: 'components' })}
                  </Button>
               </Stack>
            </Stack>
         </PageHeader>
         <Grid container spacing={2}>
            {orderLoading ? (
               <CircleLoading />
            ) : (
               <>
                  <Grid item xs={12} md={6}>
                     <PageContent>{renderInfo}</PageContent>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <PageContent>{renderForm}</PageContent>
                  </Grid>
                  <Grid item xs={12}>
                     <PageContent>{renderOrderItems}</PageContent>
                  </Grid>
               </>
            )}
         </Grid>
      </PageWrapper>
   )
}

export default OrderDetail
