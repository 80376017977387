import { FC, useEffect, useMemo, useState } from 'react'
import { CircleLoading, PopupNoti } from '../../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../../components/Table'
import {
   PageHeader,
   PageContent,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import {
   selectFetch,
   selectUser,
   updateUserInfo,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/tick.svg'
import { colorPalette } from '../../../config'
import { useTranslation } from 'react-i18next'
import { UserData } from '../../../types'
import useFilteredQuery from '../../../hooks/use-swr/use-query-with-filter'
import { warningMsg } from '../../../schemas'

const finalWarning = {
   __html: warningMsg.__html.replace('xoá', 'thay đổi'),
}

const UsersTable: FC = () => {
   const { uploading } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const [selectedUser, setSelectedUser] = useState<string>('')
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const {
      filter,
      controlMethods: {
         handleChangeEndDate,
         handleChangePageNum,
         handleChangePageSize,
         handleChangeStartDate,
         handleAddSearchCriteria,
         handleRemoveSearchCriteria,
      },
      response: {
         isLoading,
         data: users,
         paginationInfo,
         mutate,
         isValidating,
      },
   } = useFilteredQuery<UserData[]>({ url: 'user' })

   const handleOnSearch = (searchTerm: string, type: string) => {
      if (!searchTerm) {
         return handleRemoveSearchCriteria(type)
      }

      return handleAddSearchCriteria(type, searchTerm)
   }

   const handleOnPageNumChange = (pageNum: number) => {
      handleChangePageNum(pageNum)
   }

   const handleOnPageSizeChange = (pageSize: number) => {
      handleChangePageSize(pageSize)
   }

   const handleOnClick = (userId: string) => {
      setSelectedUser(userId)
      setOpenAlert(true)
   }

   const handleRevoke = () => {
      if (users) {
         const curUser = users.find((user) => user?._id === selectedUser)

         if (curUser) {
            dispatch(
               updateUserInfo({
                  userId: selectedUser,
                  payload: {
                     email: curUser?.email,
                     status: !curUser.status,
                  },
               })
            )
         }
      }
   }

   const { t } = useTranslation(['common', 'components', 'rows'])

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'ID',
      },
      {
         id: 'email',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: t('common.email', { ns: 'rows' }),
      },
      {
         id: 'created_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.createdAt', { ns: 'rows' }),
      },
      {
         id: 'updated_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.updatedAt', { ns: 'rows' }),
      },
   ]

   const rows: TableRowType[] = useMemo(
      () =>
         users
            ? users.map((user) => ({
                 key: user._id,
                 cells: [
                    {
                       identifier: '_id',
                       type: TableCellDataType.ID,
                       data: user._id,
                    },
                    {
                       identifier: 'email',
                       type: TableCellDataType.TEXT,
                       data: user.email,
                    },
                    {
                       identifier: 'created_at',
                       type: TableCellDataType.DATE,
                       data: user.created_at,
                    },
                    {
                       identifier: 'updated_at',
                       type: TableCellDataType.DATE,
                       data: user.updated_at || user.created_at,
                    },
                    {
                       identifier: 'action',
                       type: TableCellDataType.ACTION,
                       data: [
                          {
                             icon: {
                                src: PenIcon,
                                color: colorPalette.grey,
                                tooltip: t('tooltip.common.edit', {
                                   ns: 'components',
                                }) as string,
                             },
                             link: `/users/${user._id}`,
                          },
                          {
                             icon: {
                                src: user?.status ? CloseIcon : CheckIcon,
                                color: user.status
                                   ? colorPalette.red.shade_500
                                   : colorPalette.green.shade_500,
                                tooltip: user.status ? 'Chặn' : 'Bỏ chặn',
                             },
                             handleOnClick: () => handleOnClick(user._id),
                          },
                       ],
                    },
                 ],
              }))
            : [],
      [users]
   )

   useEffect(() => {
      if (!uploading && selectedUser) {
         mutate({
            data: users?.reduce<UserData[]>((acc, cur) => {
               acc.push(
                  cur._id === selectedUser
                     ? { ...cur, status: !cur.status }
                     : cur
               )
               return acc
            }, []),
            statusCode: 200,
         })
         setSelectedUser('')
      }
   }, [uploading])

   return (
      <PageWrapper>
         <PageHeader>
            <PageTitle type='header' title={t('pageTitle.user.all')} />
         </PageHeader>
         <PageContent>
            <Table
               loading={isLoading || isValidating}
               initialSort='email'
               initialSearchTermType='email'
               title={t('tableTitle.user')}
               headCells={headCells}
               rows={rows}
               withAction={true}
               totalPage={paginationInfo?.pageTotal}
               totalRecords={paginationInfo?.totalDocument}
               pageNum={filter?.pageNum}
               pageSize={filter?.pageSize}
               onEndDateChange={handleChangeEndDate}
               onSearchTermChange={handleOnSearch}
               onStartDateChange={handleChangeStartDate}
               onPageNumChange={handleOnPageNumChange}
               onPageSizeChange={handleOnPageSizeChange}
            />
            <PopupNoti
               open={openAlert}
               setOpen={setOpenAlert}
               message={finalWarning}
               action={handleRevoke}
            />
         </PageContent>
      </PageWrapper>
   )
}

export default UsersTable
